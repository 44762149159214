<template>
  <div
    class="filedrop"
    @click="selectFile"
    @drop.prevent="fileDropped"
    @dragenter.prevent
    @dragover.prevent
  >
    <i class="fas fa-file-import fa-2x" />
    <p class="mb-0 mt-3">Drag &amp; drop here or click to select a file</p>
    <p
      class="mb-0 mt-3 badge badge-light px-3 py-2 font-weight-normal"
      v-if="note"
    >
      {{ note }}
    </p>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: ["note", "accept"],
  methods: {
    fileDropped(e) {
      const files = e.dataTransfer.files;

      if (files.length !== 1) {
        return Swal.fire({
          title: "<h5>Only 1 file allowed to be imported</h5>",
          icon: "error",
        });
      }

      if (!this.accept?.includes(files[0].type)) {
        return Swal.fire({
          title: `<h5>${this.note}</h5>`,
          icon: "error",
        });
      }

      this.$emit("selected", files[0]);
    },
    selectFile() {
      const fileInput = document.createElement("input");

      fileInput.type = "file";

      if (this.accept) fileInput.accept = this.accept;

      fileInput.click();

      fileInput.onchange = (event) => {
        if (event.target.files.length < 1) return;

        this.$emit("selected", event.target.files[0]);
      };
    },
  },
};
</script>