<template>
  <div class="media-preview">
    <div class="media-header">
      <div class="d-flex justify-content-start align-items-center">
        <div>
          <h1 class="d-flex justify-content-start align-items-center">
            {{ title }}
          </h1>
          <h3>
            {{ subtitle }}
          </h3>
        </div>
      </div>
      <div>
        <div class="btn-group mr-4" v-if="hasNavigation">
          <button class="btn bg-muted border border-default-dark shadow-sm btn-sm" @click="navigate('previous')"
            :disabled="!hasNavigation.previous">
            <i class="fas fa-angle-left" />
          </button>
          <button class="btn bg-muted border border-default-dark shadow-sm btn-sm" @click="navigate('next')"
            :disabled="!hasNavigation.next">
            <i class="fas fa-angle-right" />
          </button>
        </div>
        <button class="btn border shadow-sm btn-close" @click="closePreview()">
          &times;
        </button>
      </div>
    </div>
    <div class="media-container p-0">
      <div class="placeholder" v-if="!media || !media.url">
        <span class="text-muted">No preview available</span>
      </div>
      <div class="placeholder" v-else-if="isLoading">
        <div class="spinner-border text-primary mr-3"></div>
        <span class="text-muted">Loading preview...</span>
      </div>
      <div id="osd" class="media-preview-osd" :class="{
        invisible: isLoading || !media || !media.mime.includes('image'),
      }">
        <div class="osd-buttons">
          <button class="btn bg-muted border border-secondary text-dark shadow-sm" id="osd-zoom-in">
            <i class="fas fa-plus" />
          </button>
          <button class="btn bg-muted border border-secondary text-dark shadow-sm" id="osd-zoom-out">
            <i class="fas fa-minus" />
          </button>
          <button class="btn bg-muted border border-secondary text-dark shadow-sm" id="osd-home">
            <i class="fas fa-home" />
          </button>
          <button class="btn bg-muted border border-secondary text-dark shadow-sm" id="osd-full-page">
            <i class="fas fa-expand" />
          </button>
        </div>
      </div>
      <div class="media-preview-osd" :class="{
        invisible: isLoading || !media || !media.mime.includes('video'),
      }">
        <video id="videoPlayer" controls>
          <source id="videoSource" />
        </video>
      </div>
      <div class="media-preview-osd" :class="{
        invisible: isLoading || !media || !media.mime.includes('gif'),
      }">
        <img :src="media.url" class="gif" />
      </div>
      <div class="media-preview-osd" :class="{
        invisible: isLoading || !media || !media.mime.includes('pdf'),
      }">
        <embed :src="media.url?.concat(`?timestamp=${Date.now()}`)" class="gif" />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import OSD from "openseadragon";

export default {
  name: "media-preview",
  props: ["title", "subtitle", "media", "hasNavigation"],
  data() {
    return {
      osd: null,
      isLoading: false,
    };
  },
  watch: {
    media: {
      handler() {
        this.loadOSD();
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    loadOSD() {
      $("body").off("keydown");

      let videoPlayer = document.getElementById("videoPlayer");

      let videoSource = document.getElementById("videoSource");

      if (this.media && this.media.url) {
        this.isLoading = true;

        if (this.media.mime.includes("image")) {
          if (this.osd) {
            this.osd.close();

            this.$nextTick(() => {
              this.osd.open({
                type: "image",
                url: `${this.Helper.formatMediaUrl(this.media)}`,
              });
            });
          } else {
            this.osd = OSD({
              id: "osd",
              prefixUrl: "/osd/",
              crossOriginPolicy: "Anonymous",
              maxZoomPixelRatio: 10,
              navigationControlAnchor: OSD.ControlAnchor.ABSOLUTE,
              zoomInButton: "osd-zoom-in",
              zoomOutButton: "osd-zoom-out",
              homeButton: "osd-home",
              fullPageButton: "osd-full-page",
            }).open({
              type: "image",
              url: `${this.media.url &&
                  this.media.url.includes("data:image/png;base64,")
                  ? this.media.url
                  : this.Helper.formatMediaUrl(this.media)
                }`,
            });

            this.osd.addHandler("open", () => {
              this.isLoading = false;
            });
          }
        } else if (this.media.mime.includes("video")) {
          if (videoPlayer && videoSource) {
            videoPlayer.pause();

            videoSource.src = this.Helper.formatMediaUrl(this.media);

            videoSource.type = this.media.mime;

            videoPlayer.load();

            videoPlayer.oncanplay = () => {
              this.isLoading = false;
            };
          } else {
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }

        $("body").on("keydown", (e) => {
          if (e.key == "Escape") {
            this.closePreview();
          } else if (this.hasNavigation) {
            if (e.key == "ArrowLeft" && this.hasNavigation.previous) {
              this.navigate("previous");
            } else if (e.key == "ArrowRight" && this.hasNavigation.next) {
              this.navigate("next");
            }
          }
        });
      }
    },
    navigate(direction) {
      this.$emit("navigate", direction);
    },
    closePreview() {
      $("body").off("keydown");

      this.$nextTick(() => {
        this.$emit("close");
      });
    },
  },
  mounted() {
    $("body").addClass("overflow-hidden");

    this.loadOSD();
  },
  beforeDestroy() {
    $("body").removeClass("overflow-hidden");

    this.closePreview();
  },
};
</script>

<style lang="scss" scoped>
.btn:disabled {
  opacity: 0.1 !important;
}
</style>